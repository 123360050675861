import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCode, faPaintBrush, faMusic, faPen } from "@fortawesome/free-solid-svg-icons";

const INFO = {
	main: {
		title: "Bangy",
		name: "Bangy",
		email: "bangystudios@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/bangystudios",
		github: "https://github.com/bangystudios",
		linkedin: "https://linkedin.com/in/icbix",
		instagram: "https://instagram.com/bangystudios",
        youtube: "https://youtube.com/bangy", 
		stackoverflow: "https://stackoverflow.com/users/11685079/bangystudios",
		facebook: "https://facebook.com/bangystudios",
	},

	homepage: {
		title: "Hey guys Bangy here!",
		description:
			"I’m a student currently studying and researching computer science, specializing in deep learning, artificial intelligence and embedded systems. I also like to draw and play the violin. Art and music have been of my favourite hobbies ever since high school, and it’s something that I’ve been virgorously trying to improve on. I’ve always had deep sentimental connections with aesthetic backgrounds, cute characters and fantasy melodies, and endeavour to improve upon my skills, so I can better share them with the world!",
	},

	about: {
		title: "Coming soon.",
		description:
			"Lorem ipsum, or something.",
	},

	articles: {
		title: "Coming soon.",
		description:
			"Lorem ipsum, or something.",
	},

	projects: [
        {
			title: "My Blog",
			description: "Illustrations and random stuff that pop into my head.",
			logo: faPen,
			linkText: "blog.ban.gy",
			link: "https://blog.ban.gy",
		}, 
        // {
		// 	title: "BangyStudios",
		// 	description: "Miscellaneous videos related to technology, art and music.",
		// 	logo: faYoutube,
		// 	linkText: "View on YouTube",
		// 	link: "https://youtube.com/bangy",
		// }, 
        // {
		// 	title: "BangyDraws",
		// 	description: "Illustrations and artworks.",
		// 	logo: faPaintBrush,
		// 	linkText: "View on Instagram",
		// 	link: "https://instagram.com/bangystudios",
		// }, 
        // {
		// 	title: "BangyCodes",
		// 	description: "Open-source side projects.",
		// 	logo: faCode,
		// 	linkText: "View on GitHub",
		// 	link: "https://github.com/bangystudios?tab=repositories",
		// }, 
        {
			title: "BangyMusic",
			description: "Compositions and arrangements.",
			logo: faMusic,
			linkText: "View on Musescore",
			link: "https://musescore.com/bungy",
		},
	],
};

export default INFO;
